html { color-scheme: light dark;

--dark: oklch(32.9% 0 0);
--light: oklch(97.13% 0.0224 105.15);
--highlight: oklch(70.23% 0.20 45.04);
--highlight2: oklch(70.23% 0.1 45.04);                     
--background: light-dark(var(--light), var(--dark));
--text: light-dark(var(--dark), var(--light));
     }

@font-face { font-family: 'Inconsolata'; font-style: normal; font-weight: 400; src: local("Inconsolata Regular"), local("Inconsolata-Regular"), url(https://fonts.gstatic.com/s/inconsolata/v18/QldKNThLqRwH-OJ1UHjlKGlZ5qg.woff2) format("woff2"); unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

body { background: var(--background); color: var(--text); font-size: 2.5em; font-family: "Inconsolata", monospace; }

a:link { color: var(--highlight); }

a:visited { color: var(--highlight2); }

hr { color: var(--highlight); border: 1px dashed; }

.right { float: right; max-width: 35%; margin: 5%; }

.strike { text-decoration: line-through; }

@media screen and (min-device-width: 700px) and (max-device-width: 1100px) { body { font-size: 2em; } }

@media screen and (min-device-width: 1101px) { body { font-size: 1.2em; margin-left: 50px; max-width: 850px; } }

ol { padding-left: 10%; }

ul { padding-left: 10%; }

ul.contents { list-style: none; margin-top: 0px; }

ul.sticky { list-style: none; margin-bottom: 0px; }

ul.sticky li:before { content: '\26a1  '; font-size: 80%; }

.hide { display: none; }

.footnotes { font-size: smaller ;}
.footnotes::before {
    display: inline-block;
    content: "";
    border-top: 1px dashed var(--highlight);
    width: 100%;
}

sup { vertical-align: top; font-size: 0.6em; }
